<template>
    <div class="cuenta-bloques">
        <nav-bar sizetitle="sm" nivel="1"></nav-bar>
        <header-type-game title="Cálculo"></header-type-game>
        <tutorial-game 
        v-if="tutorialStatus" 
        audio="tuto" 
        video="https://res.cloudinary.com/juegos-cramer/video/upload/v1617836232/juegos-cramer/tutoriales/nivel1/percepcion/contar_por_bloques_lsjqv8.mp4"
        :text="tutorialText"></tutorial-game>
        <div v-if="!tutorialStatus&&gameRuning">
            <clock-down minuto="1" segundo="0" @setgameruning="setGameRuning()"></clock-down>
            <div class="cb__matrix-icons-container">
                
                    <div class="cb__matrix-icons">
                        <div class="cb__game-box">
                          <div
                            v-for="i in figuras"
                            :key="i.id"
                            >
                                <div 
                                class="cb__icon-box"
                                :style="'background-color:'+color+';'"
                                v-if="i==1"
                                >
                                    <svg-icon 
                                        type="mdi" 
                                        :path="iconSelected"
                                        color="white"
                                        class="cb__icon--size"
                                    ></svg-icon>
                                </div>
                                <div v-else :class="['cb__icon-box',i==0?'cb__icon-blank':'']">

                                </div>
                                
                            </div>
                        </div>
                        <div class="cb__alternatives-box">
                            <button 
                            v-for="(item,index) in alternativas"
                            :key="item.id"
                            class="cb__alternatives-item"
                            @click="getKeyAns(item.clave,index)"
                            >
                                <div class="cb__numero">
                                    {{item.numero}}
                                </div>
                                <div v-if="visibleAnswer&&(index_answerSelected==index)" :class="['cb__check-answer',answerSelected]"></div>
                            </button>
                            
                        </div>
                        <div :class="[capaProtectora]"></div>
                    </div>
                
                
            </div>
        </div>
        <div v-if="!gameRuning">
            <results-game nombrejuego="cuenta bloques" nivel="1" inteligencia="calculo"></results-game>
        </div>
    </div>
</template>
<script>

import NavBar from '@/components/NavBar.vue'
import HeaderTypeGame from '@/components/HeaderTypeGame'
import TutorialGame from '@/components/TutorialGame'
import ClockDown from '@/components/ClockDown'
import ResultsGame from '@/components/ResultsGame'
import {mapState,mapMutations} from 'vuex'

import SvgIcon from '@jamescoyle/vue-icon'
import {mdiBicycle,mdiAirplane,mdiCarEstate,mdiSailBoat,mdiHumanHandsup,mdiRadio,mdiEmoticonCool,mdiEmoticon,mdiHandWash,mdiPineTree,mdiRocketLaunch } from '@mdi/js'

export default {
    components: {
        NavBar,
        HeaderTypeGame,
        TutorialGame,
        ClockDown,
        ResultsGame,
        
        SvgIcon
    },
    data:()=>({
        icon:[mdiBicycle,mdiAirplane,mdiCarEstate,mdiSailBoat,mdiHumanHandsup,mdiRadio,mdiEmoticonCool,mdiEmoticon,mdiHandWash,mdiPineTree,mdiRocketLaunch],
        color:'',
        iconSelected:null,
        puntos:0,
        figuras:[],
        alternativas:[],
        gameRuning:true,
        answerSelected:'',
        visibleAnswer:false,
        index_answerSelected:0,
        capaProtectora:'',
        tutorialText:'¿Cuántas figuras hay? Señale el número correcto',
        bancoDeJuegos:{
                '1':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                    ],
                '2':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '3':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,1,0,0,0,
                        0,0,0,0,0,0,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '4':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '5':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '6':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,1,0,0,0,0,0,0,
                        0,0,0,1,1,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '7':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,0,0,1,1,0,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '8':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,0,0,0,0,1,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '9':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,1,1,0,0,0,
                        0,0,0,0,0,0,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '10':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,1,0,0,0,0,
                        0,0,0,0,0,1,1,1,0,0,0,
                        0,0,0,0,0,1,1,1,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '11':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,1,0,0,0,0,
                        0,0,0,0,0,1,1,1,0,0,0,
                        0,0,0,0,0,1,1,1,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '12':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '13':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,1,1,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,0,0,0,0,0,0,
                        0,0,0,1,1,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '14':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,1,1,1,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '15':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,0,0,0,0,
                        0,0,0,0,1,1,0,0,0,0,0,
                        0,0,0,0,0,0,1,1,0,0,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '16':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '17':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,1,1,0,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '18':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,1,1,0,0,0,1,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,1,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '19':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,1,1,0,0,
                        0,0,0,1,0,0,0,1,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '20':[
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,1,0,0,0,0,1,1,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,1,1,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                ],
                '21':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,0,1,0,0,0,1,0,1,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,1,0,1,0,0,0,1,0,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '22':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,0,1,0,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,0,0,0,1,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '23':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,1,0,0,0,1,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,1,0,0,0,1,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '24':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,1,0,0,0,0,0,0,0,
                        0,0,1,1,1,1,1,0,0,0,0,
                        0,0,1,1,1,1,1,0,0,0,0,
                ],
                '25':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,1,1,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '26':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,1,1,0,0,0,
                        0,0,0,0,1,1,0,0,0,0,0,
                        0,0,1,1,0,0,0,0,0,0,0,
                        1,1,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '27':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,1,1,0,
                        0,1,0,0,0,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,0,0,0,0,1,0,
                        0,1,1,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '28':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,1,1,1,1,1,1,0,0,
                        0,0,0,0,1,1,1,1,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '29':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,1,1,1,0,0,0,0,1,0,
                        0,1,1,1,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '30':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,1,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '31':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,1,0,0,0,1,0,
                        0,1,1,0,0,1,0,0,1,1,0,
                        0,1,1,1,0,1,0,1,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '32':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,0,0,0,0,0,0,0,0,0,
                        0,1,1,1,0,0,0,0,0,0,0,
                        0,0,1,1,1,0,0,0,0,0,0,
                        0,0,0,1,1,1,0,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '33':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,1,1,0,0,
                        0,1,1,0,0,0,0,0,1,1,0,
                        1,1,0,0,0,0,0,0,0,1,1,
                        1,0,0,0,0,0,0,0,0,0,1,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '34':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,1,1,0,0,0,0,0,1,1,0,
                        0,1,1,0,0,0,0,0,1,1,0,
                        0,1,0,0,0,1,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '35':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,1,1,0,0,
                        0,0,0,0,0,0,1,1,1,1,0,
                        0,0,0,0,0,0,0,1,1,1,0,
                        0,0,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '36':[
                        0,0,0,0,0,0,0,1,1,1,1,
                        0,0,0,0,0,0,0,0,0,0,1,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,0,0,0,0,0,0,0,0,0,1,
                        1,1,1,1,0,0,0,1,1,1,1,
                ],
                '37':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '38':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,1,1,1,1,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,1,0,0,0,0,0,
                        0,0,0,1,1,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,0,0,0,0,
                        1,1,1,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '39':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,1,0,0,0,0,0,
                        0,0,0,1,1,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,0,0,0,0,
                        0,1,1,0,0,0,0,0,0,1,1,
                        1,1,0,0,0,0,0,0,0,1,1,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '40':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,1,1,1,1,
                        0,1,0,0,0,0,1,1,1,1,0,
                        1,1,0,0,0,0,0,0,0,0,0,
                        1,1,0,0,0,0,0,0,0,0,0,
                        1,1,0,0,0,0,0,0,0,0,0,
                        1,0,0,0,0,0,0,0,0,0,0,
                ],
                '41':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,1,1,1,0,0,0,1,1,1,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,1,0,1,0,0,0,0,
                        0,1,1,1,0,0,0,1,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '42':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,0,1,1,1,1,1,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '43':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,1,1,1,1,1,1,0,0,
                        0,0,0,0,0,1,1,1,1,0,0,
                        0,0,0,0,0,0,1,1,1,0,0,
                        0,0,0,0,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '44':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,0,0,0,0,0,1,1,1,1,0,
                        1,0,0,0,0,0,0,0,0,0,0,
                        1,0,0,0,0,1,0,0,0,1,0,
                        1,0,0,0,1,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,0,1,0,
                        1,1,1,1,0,0,0,0,0,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '45':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,0,1,1,1,
                        0,0,0,0,1,0,0,1,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,0,1,1,0,0,0,0,
                        0,0,0,0,1,0,0,1,0,0,0,
                        0,1,1,1,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '46':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,1,0,0,0,0,0,0,1,1,0,
                        1,1,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,1,1,0,0,0,0,0,
                        1,1,0,0,0,0,0,0,1,1,0,
                        1,1,0,0,0,0,0,0,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '47':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,1,1,0,0,0,0,0,0,0,0,0,
                        1,1,1,0,0,0,0,0,0,0,0,
                        1,1,1,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,1,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,1,1,1,
                        0,0,0,0,0,0,0,0,1,1,1,
                        0,0,0,0,0,0,0,0,1,1,1,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '48':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,0,0,0,1,1,0,0,
                        0,0,0,1,1,0,1,1,0,0,0,
                        0,0,0,0,1,1,1,0,0,0,0,
                        0,0,0,1,1,0,1,1,0,0,0,
                        0,0,1,1,0,0,0,1,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '49':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,1,1,1,1,0,
                        0,0,0,1,1,0,1,1,1,1,0,
                        0,0,0,1,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        1,1,1,1,0,0,0,0,0,0,0,
                        1,1,1,1,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '50':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,1,1,1,0,0,0,1,1,1,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,1,1,1,0,0,0,1,1,1,0,
                        0,0,1,0,0,0,0,0,1,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '51':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,1,1,1,1,1,1,0,0,
                        0,0,0,1,1,1,1,1,1,1,0,
                        0,0,0,0,1,1,1,1,1,1,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],
                '52':[
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,1,0,0,0,1,0,0,0,0,
                        0,0,1,0,1,0,1,0,1,0,0,
                        0,0,1,0,1,0,1,0,1,0,0,
                        0,0,1,0,1,0,1,0,1,0,0,
                        0,0,1,0,1,0,1,0,1,0,0,
                        0,0,1,0,0,0,1,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                        0,0,0,0,0,0,0,0,0,0,0,
                ],

            }
        }),
    computed:{
        ...mapState(['tutorialStatus'])
    },
    methods:{
            ...mapMutations(['changeMenuBarBottom','changeScoreGame','changeScoreDescription','changeTutorialStatus']),
            setGameRuning(){
                this.gameRuning = false
                if(this.puntos<0)
                {
                    this.changeScoreGame(0)
                    this.changeScoreDescription('insuficiente')
                }
                else{
                    this.changeScoreGame(this.puntos)
                    if(this.puntos<=200){
                    this.changeScoreDescription('insuficiente')
                    }
                    else if(this.puntos<=400){
                        this.changeScoreDescription('escasa')
                    }
                    else if(this.puntos<=600){
                        this.changeScoreDescription('suficiente')
                    }
                    else if(this.puntos<=800){
                        this.changeScoreDescription('buena')
                    }
                    else{
                        this.changeScoreDescription('optima')
                    }
                }
            },
            getKeyAns(key,index){
                
                this.visibleAnswer = true
                this.index_answerSelected = index
                this.capaProtectora = 'cb__capa-protectora'
                if(key){
                    let a = new Audio(require('../../../assets/juegos/audios/acierto.mp3'))
                    a.play()
                    this.puntos = this.puntos + 70;
                    this.answerSelected = 'cb__correct-answer'
                }
                else{
                    let a = new Audio(require('../../../assets/juegos/audios/fallo.mp3'))
                    a.play()
                    this.puntos = this.puntos - 10;
                    this.answerSelected = 'cb__wrong-answer'
                }

                window.setTimeout(()=>{
                    this.capaProtectora=''
                    this.gameGenerator()
                },1000)
            },
            getRandom(min, max) {
                return Math.floor(Math.random() * (max - min+1) ) + min;
            },
            getArrayResRandom(min,max,nro){
                //let total = max-min;
                let res = [];
                res.push({
                    numero:nro,
                    clave:true
                })
                while(!(res.length==3))
                {
                    let aux = this.getRandom(min,max);
                    let a = res.find((e)=>e.numero==aux)
                    if(!a&&a!=nro){
                        res.push({
                            numero:aux,
                            clave:false
                        })
                    }
                }
                
                return res;
            },
            createColor(){
                let red = this.getRandom(10,200);
                let green = this.getRandom(10,200);
                let blue = this.getRandom(10,200);
                return 'rgb('+red+','+green+','+blue+')'
            },
            contarFiguras(){
                let sum=0;
                this.figuras.forEach(e=>{
                    if(e==1){
                        sum++;
                    }
                })
                return sum;
            },
            reordenarArray(arr){
                let a = arr.sort(function() { return Math.random() - 0.5 })
            // console.log(a)
                return a;
            },
            gameGenerator(){
                this.visibleAnswer = false
                this.answerSelected = ''
                this.color = this.createColor()
                this.iconSelected = this.icon[this.getRandom(0,this.icon.length-1)]
                this.figuras = this.bancoDeJuegos[this.getRandom(1,52)]
                let totalFiguras = this.contarFiguras()
                let lim_inf = totalFiguras-3;
                let lim_sup = totalFiguras+3;
                if(lim_inf<0){lim_inf=0}
                let alternativas = this.getArrayResRandom(lim_inf,lim_sup,totalFiguras)
                console.log(alternativas)
                this.alternativas = []
                this.alternativas = this.reordenarArray(alternativas)
                
            }
        },
        mounted(){
            this.changeMenuBarBottom(false)
            this.gameGenerator()
            this.changeTutorialStatus(true)
            window.setTimeout(()=>{
                this.tutorialText = 'Ahorre tiempo contando por bloques, sumando, restando y multiplicando si es necesario.'
            },2500)
        }
}
</script>
<style>
.cuenta-bloques{
    width: 100%;
    height: 100vh;
    background-color: #1F0275;
}
.cb__matrix-icons-container{
    margin-top: 2em;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cb__game-box{
    
    width: 90%;
    height: 40vh;
    display: grid;
    grid-template-columns: repeat(11,7.6%);
    grid-template-rows: repeat(10,8%);
    grid-gap:5px;
}
.cb__matrix-icons{
    width: 95%;
    height: 50vh;
    background-color: #EEEFE4;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cb__icon-box{
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: #a1a1a1 1px 1px 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cb__icon-blank{
    background-color: transparent;
    box-shadow: transparent 0px 0px 0px;
}
.cb__alternatives-box{
    width: 90%;
    height: 4em;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.cb__alternatives-item{
    width: 3em;
    height: 3em;
    background-color: rgb(150, 99, 99);
    border-radius: 10px;
    border:rgb(73, 47, 47) 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
}
.cb__numero{
    font-size: 2em;
    color:rgb(221, 218, 218);
}
.cb__icon--size{
    width: 1em;
    height: 1em;
}

.cb__check-answer{
    position: absolute;
    width:1.5em;
    height: 1.5em;
    top:-8px;
    right: -8px;
    background-size: 100% 100%;
}
.cb__wrong-answer{
    background-image: url('../../../assets/juegos/comunes/cancelar.svg');
}
.cb__correct-answer{
    background-image: url('../../../assets/juegos/comunes/comprobado.svg');
}
.cb__capa-protectora{
    width: 100%;
    height: 8em;
    background-color: red;
    opacity: 0;
    bottom: 0px;
    position: absolute;

}
@media (min-width: 900px) and (orientation: landscape) {
    .cb__matrix-icons{
        width: 60%;
    }
    
}
</style>